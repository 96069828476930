import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Component/Home/Home";
import LcgRealEstate from "./Component/LcgRealEstate";
import AboutUs from "./Component/About Us/AboutUs";
import Aimart from "./Aimartrealtors/AimartrealtorsClone/Aimart";
import LandingPage from "./Aimartrealtors/LandingPage/LandingPage";
import AimartHome from "./Aimartrealtors/Home/AimartHome";
import AimartAboutUs from "./Aimartrealtors/AboutUsPage/AimartAboutUs";
import IslandLandinPage from "./Aimartrealtors/LandingPage/IslandLandinPage";
import IbogunLekki from "./Aimartrealtors/IsLand/IbogunLekki";
import Arapaji from "./Aimartrealtors/IsLand/Arapaji";
import NavBar from "./Component/NAVBAR/NavBar";
import Footer from "./Component/Footer/Footer";
import ScrollToTop from "./Component/ScrollToTop";
import Services from "./Component/Services/Services";
import ContactUs from "./Component/ContactUs/ContactUs";
import PalmsPark from "./Aimartrealtors/Mainland/PalmsPark";


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <NavBar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="about-us" element={<AboutUs/>}/>
        <Route path="landing-page" element={<LandingPage/>}/>
        <Route path="home" element={<AimartHome/>}/>
        <Route path="about-us-aimart" element={<AimartAboutUs/>}/>
        <Route path="island-landing-page" element={<IslandLandinPage/>}/>
        <Route path="ibogun-lekki" element={<IbogunLekki/>}/>
        <Route path="arapaji" element={<Arapaji/>}/>
        <Route path="service" element={<Services/>}/>
        <Route  path="contact-us" element={<ContactUs/>}/>
        <Route path="palms-park" element={<PalmsPark/>} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
