import React from 'react'
import image from "../../ASSETS/Frame 29.png"
import { NavLink } from 'react-router-dom'

const SatisfiedClient = () => {
  return (
    <section className="font-asul w-full my-6 ">
      <article className="flex items-center gap-2 w-11/12  h-[350px] mx-auto lg:h-[700px] lg:w-11/12 lg:gap-5 ">
        <div className=" lg:w-3/12 ">
          <img src={image} alt="" className="w-[300px] hidden lg:block h-[280px] lg:h-[700px]" />
        </div>
        {/* Right side */}
        <div className="w-full px-6 pt-5 mx-auto h-[300px] rounded-[25px] lg:h-[700px] bg-[#F7F8F8] lg:pt-12 lg:px-52 lg:rounded-[55px] font-asul lg:w-11/12  ">
          <div className="flex flex-col gap-3 lg:gap-16  ">
            <p className=" text-[#050F27] text-xs lg:text-lg text-end">
              Join our Satisfied Clients
            </p>
            <div className="flex flex-col items-start gap-3 lg:gap-12  pt-12 lg:pt-0">
              <h2 className="text-[#050F27] font-bold text-sm text-center lg:text-start lg:font-semibold lg:text-5xl ">
                We can bring to life everything you've ever envisioned and
                dreamed of – your ideals and desires are our creations.
              </h2>
              <p className="text-justify text-xs font-normal text-[#050F27] lg:text-2xl">
                Our purpose is to transform your wildest dreams into reality.
                Regardless of how unconventional your vision may be, we possess
                the capability to craft your dream home, fulfilling your every
                desire.
              </p>
              <NavLink to="/about-us">
              <button className="bg-[#050F27] text-sm rounded-lg px-[4px] py-[3px] lg:rounded-3xl text-white lg:text-xl lg:px-[33px] lg:py-[24px]">Learn More</button>
              </NavLink>
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}

export default SatisfiedClient
