import React from "react";
import PowerTower from "../../ASSETS/electric-tower-qn4rh4xde7n336w5cq4yj9qiksvktgvxtb71wv7560.gif";
import CBD from "../../ASSETS/building-qn4s3rvfzkmop40e60ei3235lvat71r7xct0zrmtbc.gif";
import TechHub from "../../ASSETS/hard-drive-qn4se0c6emn34956efo98h3ugwup0ee000jd48ghk8.gif";
import Recreation from "../../ASSETS/forest-qn4sjp8zsiflf8vj5w8xc1ebywspmezhg6r7qk0nvs.gif";
import garden from "../../ASSETS/garden.jpg";

const IbogunExperienceSection = () => {
  return (
    <section className="w-full h-auto md:h-[600px] flex flex-col items-center justify-center md:flex-row">
      {/* Left Side */}
      <div className="w-full md:w-1/2 py-12 px-6 md:py-24 md:pr-20 md:pl-36 space-y-4 md:space-y-5">
        <h1 className="text-2xl md:text-3xl font-bold text-center md:text-end">
          Experience the Essence of Luxury at Landmark Park Phase 2.
        </h1>
        <p className="text-center md:text-end">
          Located in the idyllic setting of Ibogun Ibeju Lekki, this esteemed
          neighborhood embodies tranquility and sophistication, offering
          residents a life of unparalleled comfort and elegance.
        </p>
      </div>

      {/* Right Side */}
      <div
        className="w-full md:w-1/2 h-full bg-cover bg-center flex items-center justify-start py-10 md:py-0"
        style={{ backgroundImage: `url(${garden})` }}
      >
        <div className="bg-white w-7/12 md:w-11/12 h-full md:h-4/5 py-8 md:py-12  ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-12 h-full w-full md:w-11/12 mx-auto ">
            <img src={PowerTower} alt="Power Tower" className="w-full h-24 md:h-32 object-contain" />
            <img src={CBD} alt="CBD" className="w-full h-24 md:h-32 object-contain" />
            <img src={TechHub} alt="Tech Hub" className="w-full h-24 md:h-32 object-contain" />
            <img src={Recreation} alt="Recreation" className="w-full h-24 md:h-32 object-contain" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default IbogunExperienceSection;
