import React from "react";
import IbejuLekki from "../../ASSETS/covenant-park.png.webp";
import Origanrigan from "../../ASSETS/Logo-Emblem-1.svg";
import BadgeProps from "../Reuseable/BadgeProps";

const IslandLandinPage = () => {
  const badge = [
    {
      id: 1,
      img: Origanrigan,
      text: "Origanrigan Ibeju Lekki",
      route: "/ibogun-lekki"
    },
    {
      id: 2,
      img: IbejuLekki,
      text: "Phase 2 Ibogun Ibeju Lekki",
      route: "/ibogun-lekki",
    },
    {
      id: 3,
      img: IbejuLekki,
      text: "Arapagi Elerangbe Ibeju Lekki",
      route: "/arapaji",
    },
  ];

  return (
    <section className="w-11/12 mx-auto rounded-3xl bg-gray-100/50 flex items-center justify-center my-8 md:my-12">
      <article className="w-10/12 md:w-9/12 mx-auto min-h-screen md:min-h-full grid grid-cols-1 md:grid-cols-3 gap-4">
        {badge.map((items) => (
          <div key={items.id} className="flex justify-center">
            <BadgeProps img={items.img} text={items.text} route={items.route} />
          </div>
        ))}
      </article>
    </section>
  );
};

export default IslandLandinPage;
