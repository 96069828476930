import React from 'react'
import image from "../../ASSETS/Rectangle 750.png"

const AboutUsHero = () => {
  return (
    <section className='w-full mt-8 lg:mt-24'>
    <div className=' w-full px-8 lg:px lg:w-9/12 mx-auto flex flex-col items-center gap-4 lg:gap-8'>
        <div> 
          <h1 className='font-semibold w-96 font-asul text-5xl lg:text-7xl text-center lg:w-[800px] mx-auto'>Your trusted partner </h1>
          </div>
        <div><img src={image} alt="" className='w-full lg:w-[800px]' /></div>
    </div>

   </section>
  )
}

export default AboutUsHero
