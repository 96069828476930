import React from 'react';
import NationalTheatre from "../../ASSETS/National-Theatre-1280x640.jpg";
import Lekki from "../../ASSETS/lekki_2.png";
import Project from './Project';

const MainLand = () => {
  return (
    <section
      id="mainland-section" 
      className="w-11/12 mx-auto bg-gray-50 h-auto flex flex-col items-center gap-10 rounded-2xl py-24 mb-10 justify-center"
    >
      <div className="flex flex-col items-center justify-center">
        <h1 className="font-roboto text-5xl mb-8 uppercase font-medium">
          Projects
        </h1>
        <p className="font-roboto font-normal text-sm">
          Explore our available properties
        </p>
      </div>

      <div className="w-full flex flex-col md:flex-row items-center justify-center gap-5">
        <Project
          backgroundImage={NationalTheatre}
          landText="Mainland"
          route="landing-page"
        />
        <Project
          backgroundImage={Lekki}
          landText="Island"
          route="island-landing-page"
        />
      </div>
    </section>
  );
};

export default MainLand;
