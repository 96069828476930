import React from "react";
import { useNavigate } from "react-router-dom";
import Image1 from "../../ASSETS/Rectangle 32.png";
import Image2 from "../../ASSETS/Rectangle 34.png";
import Image3 from "../../ASSETS/Arrow01.svg";

const OurValue = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact-us");
  };

  return (
    <section className="w-full font-spaceGrotesk py-24">
      {/* Container for both sides */}
      <div className="w-10/12 mx-auto flex flex-col md:flex-row gap-6 items-stretch justify-center">
        {/* Left Side */}
        <div className="w-full md:w-[43%] flex">
          <img
            src={Image1}
            alt=""
            className="w-full h-full object-cover rounded-xl"
          />
        </div>

        {/* Right Side */}
        <div className="flex flex-col-reverse gap-6 md:gap-0 md:flex-row w-full lg:w-11/12">
          {/* Right side Image */}
          <div className="w-full md:w-[57%] flex">
            <img src={Image2} alt="" className="w-full h-full object-cover" />
          </div>

          {/* Right Side Text */}
          <div className="m-0 md:m-8 lg:w-full flex flex-col items-center md:items-start gap-6 md:gap-12 justify-end">
            <div className="flex gap-3 items-center">
              <img src={Image3} alt="" />
              <h2 className="uppercase text-2xl font-bold">our value</h2>
            </div>
            <div className="flex flex-col gap-10 md:gap-24">
              <div>
                <h1 className="text-3xl text-[#010101] text-center md:w-[400px] flex flex-col  md:text-5xl lg:text-start">
                  Unlocking <span className=""> Real Appraisal </span>
                  <span className="text-[#6E00FF] font-medium">Services</span>
                </h1>
              </div>
              <div className="flex gap-6 flex-col-reverse md:flex-row items-center justify-center">
                <button
                  onClick={handleLearnMoreClick}
                  className="capitalize w-40 h-10 text-lg bg-black md:w-72 md:px-4 md:py-2 rounded-full text-white"
                >
                  learn more
                </button>
                <p className="font-skSynonym text-lg text-center md:text-start">
                  Our experts can provide valuable insights and assist you in
                  identifying properties.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurValue;
