import React from 'react'
import BgImage from "../../ASSETS/forestRoad3.jpg"
import Image from "../../ASSETS/covenant-park.png.webp"
import LandsHero from '../Reuseable/LandsHero'
import PlotSize from '../Reuseable/PlotSize'
import GetStarted from '../Reuseable/GetStarted'
import EveryCorner from '../Reuseable/EveryCorner'

const Arapaji = () => {
  return (
    <section className='w-11/12 mx-auto rounded-3xl my-12'>
        <div>
            <LandsHero
            backgroundImage = {BgImage}            
            Image = {Image}
            text = "Arapagi Elerangbe Ibeju Lekki."
            />
        </div>
        <EveryCorner/>
        <PlotSize/>
        <GetStarted/>
    </section>
  )
}

export default Arapaji
