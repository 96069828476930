import React from "react";
import image from "../../ASSETS/Frame 29.png";

const WhoWeAre = () => {
  return (
    <section className="font-asul w-full mt-11 lg:mt-16"> 
      <article className="w-10/12 mx-auto flex flex-col-reverse md:flex-row gap-6 items-stretch justify-center">
        <img
          src={image}
          alt=""
          className="h-[300px] lg:h-[700px]"
        />

        {/* Right side */}
        <div className="w-11/12 bg-[#F7F8F8] h-auto py-12 px-6 space-y-10 rounded-[25px] lg:rounded-[52px] mx-auto lg:p-16 lg:px-28">
          <div className="flex flex-col gap-12">
            <p className=" text-[#050F27] text-lg text-end">
              Join our Satisfied Clients
            </p>
            <div className="flex flex-col gap-6 items-start lg:gap-12">
              {/* Who we are */}
              <div className="flex flex-col gap-4">
                <h2 className="text-[#050F27] text-3xl font-semibold lg:text-5xl">
                  Who we are
                </h2>
                <p className="text-justify font-semibold text-[#050F27] text-xs lg:text-lg">
                Lauref Classical Global is a leading real estate company in Lagos, Nigeria, with over 5 years of experience. We offer a comprehensive range of services, including property sales, interior design, and general business consulting, encompassing import/export and automobile dealership. Our commitment to integrity and customer satisfaction sets us apart in the industry.
                </p>
              </div>
              {/*  Our Mission  */}
              <div className="flex flex-col gap-4">
                <h2 className="text-[#050F27] text-3xl font-semibold lg:text-5xl ">
                  Our mission
                </h2>
                <p className="text-justify font-semibold text-[#050F27] text-xs lg:text-lg">
                To provide innovative solutions and exceptional value to our clients in the areas of real estate, interior design, and general business consulting. We strive to build long-lasting relationships based on trust, transparency, and mutual benefit.
                </p>
              </div>
              {/* Our vision  */}
              <div className="flex flex-col gap-4">
                <h2 className="text-[#050F27] text-3xl font-semibold lg:text-5xl ">
                  Our vision
                </h2>
                <p className="text-justify  font-semibold text-[#050F27] text-xs lg:text-lg">
                To be the most trusted and respected real estate and business consulting firm in Nigeria, renowned for our expertise, integrity, and exceptional customer service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default WhoWeAre;
