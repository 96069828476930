import React from "react";
import skyScrapper from "../../ASSETS/skyscrapper.jpg";
import garden from "../../ASSETS/garden.jpg";
import Stabitlity from "../../ASSETS/Stability.svg";
import Reliability from "../../ASSETS/Reliability.svg";
import Transparency from "../../ASSETS/Transparency.svg";
import Trustworthiness from "../../ASSETS/Trustworthiness.svg";

const WhatSetUsApart = () => {
  return (
    <section className="flex flex-col h-full md:flex-row md:pt-0 pt-12">
      {/* The Right side */}
      <div className="w-full relative md:w-1/2 ">
        <div className="absolute inset-0 bg-black opacity-80 z-10 "></div>
        <div
          className="w-full h-full bg-cover bg-center md:p-20"
          style={{ backgroundImage: `url(${skyScrapper})` }}
        >
          <div className="relative z-20 flex flex-col justify-center h-full p-6 md:p-12 space-y-8">
            <h1 className="text-2xl font-bold text-white md:text-3xl text-center md:text-start">
              What sets us apart is our relentless pursuit of excellence and
              innovation.
            </h1>
            <p className="text-white font-normal md:text-xl text-center md:text-start text-base">
              From identifying lucrative investment opportunities to negotiating
              favorable deals and providing comprehensive support throughout the
              buying or selling process, we go above and beyond to exceed
              expectations and deliver results that matter.
            </p>
          </div>
        </div>
      </div>

      {/* The Left Side */}
      <div className="w-full relative md:w-1/2 ">
        <div className="absolute inset-0 bg-blue-700 opacity-85 z-10"></div>
        <div
          className="w-full h-full bg-cover bg-center md:p-20"
          style={{ backgroundImage: `url(${garden})` }}
        >
          <div className="relative z-20 flex flex-col justify-center h-full p-6 md:p-12 space-y-8">
            <h1 className="md:text-3xl text-2xl font-bold text-white text-center md:text-start">
              We Follow Best Practices
            </h1>
            <p className="text-white text-center md:text-start">
              As a trusted leader in the real estate industry, specializing in
              land sales, we adhere to the highest standards of professionalism
              and integrity.
            </p>
            <ul className="space-y-4  justify-center md:justify-start">
              <li className="flex text-base md:text-2xl items-center gap-4 font-semibold text-white">
                <span className="md:w-6 w-5">
                  <img src={Stabitlity} alt="" />
                </span>
                Stability
              </li>
              <li className="flex text-base md:text-2xl items-center gap-4 font-semibold text-white">
                <span className="md:w-6 w-5">
                  <img src={Reliability} alt="" />
                </span>
                Reliability 
              </li>
              <li className="flex text-base md:text-2xl items-center gap-4 font-semibold text-white">
                <span className="md:w-6 w-5">
                  <img src={Transparency} alt="" />
                </span>
                Transparency
              </li>
              <li className="flex text-base md:text-2xl items-center gap-4 font-semibold text-white">
                <span className="md:w-6 w-5">
                  <img src={Trustworthiness} alt="" />
                </span>
                Trustworthiness
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatSetUsApart;
