import React from "react";
import { NavLink } from "react-router-dom";

const Project = (props) => {
  return (
    <NavLink to={props.route} className="w-full max-w-xs">
      <section className=" rounded-2xl relative h-72 bg-white shadow p-2">
        <div className="max-w-xs rounded-2xl relative h-[273px] ">
          {/* Overlay */}
          <div className="absolute inset-0 bg-black opacity-50 z-20 rounded-2xl"></div>
          {/* Background Image Container */}
          <div
            className="w-full h-full bg-cover bg-center flex justify-center items-end pb-8  rounded-2xl "
            style={{
              backgroundImage: `url(${props.backgroundImage})`,
            }}
          >
            {/* Text Content */}
            <div className="relative z-30">
              <h1 className="font-bold text-poppins text-end text-white text-5xl uppercase shadow-2xl">
                {props.landText}
              </h1>
            </div>
          </div>
        </div>
      </section>
    </NavLink>
  );
};

export default Project;
