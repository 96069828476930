import React from "react";
import Image from "../../ASSETS/Report newsletter update subscribe information.png";

const OurContact = () => {
  return (
    <section className="w-full bg-white mt-12 font-roboto overflow-x-hidden"> {/* Overflow-x hidden to prevent any horizontal scrolling */}
      <div className="w-11/12 mx-auto gap-4 md:gap-0 flex flex-col md:flex-row items-start">
        {/* Left Side */}
        <div className="flex flex-col gap-12 w-full md:w-9/12 mx-auto"> {/* Changed width to full on mobile */}
          <div>
            <h1 className="text-[#212121] text-center md:text-start text-3xl md:text-4xl font-semibold max-w-full md:max-w-[500px]">
              You can also reach out to our dedicated team{" "}
            </h1> {/* Made the max-width responsive */}
          </div>
          <div className="space-y-4 md:space-y-8 font-semibold ">
            <h2 className="text-2xl md:text-4xl font-bold text-center md:text-start">Our contact information</h2>
            <p className="md:text-base text-lg text-center md:text-start">Email: info@lcglobal.com.ng</p>
            <p className="md:text-base text-lg text-center md:text-start">Phone number: 07017727500</p>
            <p className="text-lg md:text-base font-bold w-full md:w-72 text-center md:text-start">Address: 1b Union Road, Aluminium Village, Dopemu Agege, Lagos</p> {/* Responsive width */}
          </div>
        </div>

        {/* Right Side */}
        <div className="w-full md:w-4/12 h-full"> {/* Ensure width fits the screen */}
          <img src={Image} alt="Contact" className="w-full h-auto" /> {/* Ensure image is responsive */}
        </div>
      </div>
    </section>
  );
};

export default OurContact;
