import React from "react";
import PalmsPark from "../../ASSETS/Palms-Park-logo-3-1024x619.png.webp";
import Kingsway from "../../ASSETS/kingsway.png.webp";
import Diamaond from "../../ASSETS/diamond-park.png.webp";
import Royalty from "../../ASSETS/Royalty-Park-logo.svg";
import Treasure from "../../ASSETS/Treasure-park.png.webp";
import BadgeProps from "../Reuseable/BadgeProps";

const LandingPage = () => {
  const badge = [
    {
      id: 1,
      img: PalmsPark,
      text: "Aradogun Badagry",
      route: "/palms-park"
    },
    {
      id: 2,
      img: Kingsway,
      text: "Yobo Ewekoro",
      route: "/palms-park"
    },
    {
      id: 3,
      img: Diamaond,
      text: "Araromi - Igbesa, Agbara",
      route: "/palms-park"
    },
    {
      id: 4,
      img: Royalty,
      text: "Mokoloki - Ofada, Mowe",
      route: "/palms-park"
    },
    {
      id: 5,
      img: Treasure,
      text: "Coker Town, Ajasa Sango - Ifo",
      route: "/palms-park"
    },
  ];

  const badgeText = badge.map((items) => {
    return (
      <div key={items.id}>
        <BadgeProps img={items.img} text={items.text} route={items.route} />
      </div>
    );
  });
  return (
    <section className="w-11/12 my-12 mx-auto rounded-3xl  bg-gray-100/50">
      <article className="w-10/12 md:w-9/12 mx-auto grid-cols-1 gap-4 grid md:grid-cols-3">{badgeText}</article>
    </section>
  );
};

export default LandingPage;
