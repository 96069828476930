import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const BadgeProps = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
  }, []);

  return (
    <>
      <style>
        {`
          @keyframes zoomInOut {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }

          .zoom-animation {
            animation: zoomInOut 0.5s ease-in-out;
          }
        `}
      </style>
      <NavLink to={props.route}>
        <section className="my-12 w-full md:w-11/12 mx-auto">
          <div
            className={`flex flex-col gap-5 items-center rounded-3xl bg-white p-8 shadow-md transform transition-transform ${
              animate ? "zoom-animation" : ""
            }`}
          >
            <div className="w-64 h-72 overflow-hidden">
              <img
                src={props.img}
                alt=""
                className="object-contain w-full h-full"
              />
            </div>
            <div>
              <h2 className="font-poppins font-semibold text-sm">
                {props.text}
              </h2>
            </div>
          </div>
        </section>
      </NavLink>
    </>
  );
};

export default BadgeProps;
