import React, { useRef } from "react";
import { useNavigate, useLocation, NavLink, Link } from "react-router-dom";
import image from "../../ASSETS/Footer logo.png";
import gmail from "../../ASSETS/Gmail.png";
import Youtube from "../../ASSETS/youtube.png";
import x from "../../ASSETS/x.png";
import Instagram from "../../ASSETS/instagram.png";
import Facebook from "../../ASSETS/facebook.png";
import Whatsapp from "../../ASSETS/whatsapp.png";
import emailjs from "@emailjs/browser";



const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const navigate = useNavigate();
  const location = useLocation();

  const handleRealEstateClick = () => {
    if (location.pathname === "/") {
      const mainlandSection = document.getElementById("mainland-section");
      mainlandSection?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/?scrollToProjects=true");
    }
  };

  const gotoContact = () => {
    if (location.pathname === "/contact-us") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/contact-us");
    }
  };

  const gotoAboutUs = () => {
    if (location.pathname === "/about-us") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/about-us");
    }
  };

  // const gotoContactUs = () => {
  //   if (location.pathname === "/contact-us") {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //   } else {
  //     navigate("/contact-us");
  //   }
  // };

  const handleServiceClick = () => {
    if (location.pathname === "/about-us") {
      const serviceSection = document.getElementById("service");
      serviceSection?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/about-us", {
        state: { scrollToService: true, behavior: "smooth " },
      });
    }
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9x7wrrr",
        "template_unhgftj",
        form.current,
        "BU8yNyIVUkHJsseMn"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <footer className="w-full mt-8 font-asul pb-8">
      <div className="w-full bg-[rgb(5,15,39)] py-12 p-0 space-y-5 lg:space-y-10 rounded-tr-[45px] lg:rounded-tr-[45px] mx-auto lg:p-20 lg:px-28 text-white font-poppins">
        <div className="flex flex-col gap-20">
          <div className="flex gap-6 md:gap-0 flex-col md:flex-row items-center justify-center md:justify-between">
            {/* Left side where the Logo is */}
            <div className="space-y-5">
              <div>
                <Link to="/">
                  {" "}
                  <img src={image} alt="" className="w-16 h-12" />{" "}
                </Link>
              </div>
              <p className="font-poppins text-white text-sm">
                Subscribe to our newsletter
              </p>
              <form ref={form} onSubmit={sendEmail}>
                <input
                  type="email"
                  className="bg-[#050f27] border border-white placeholder:text-gray-100 px-4 w-60 h-10"
                  placeholder="Your Email"
                />
                <button className="border border-[#FFBD4D] rounded-r-full h-10 w-24">
                  Send
                </button>
              </form>
            </div>

            {/* Right side */}
            <div className="flex items-start justify-around gap-2 md:gap-8 p-4">
              <ul className="space-y-4 w-20 md:w-36 text-sm md:text-base">
                <NavLink to="/">
                  <li className="cursor-pointer">Home</li>
                </NavLink>
                <button onClick={handleServiceClick}>
                  <li>Services</li>
                </button>
                <li>
                  <button onClick={gotoAboutUs}>About Us </button>{" "}
                </li>
                <li>
                  <button onClick={gotoContact} className="cursor-pointer">
                    Contact Us
                  </button>
                </li>
              </ul>
              <ul className="space-y-4 w-[130px] md:w-36 text-sm md:text-base">
                <li>
                  <button
                    onClick={handleRealEstateClick}
                    className="cursor-pointer"
                  >
                    Real Estate
                  </button>
                </li>
                <button onClick={gotoContact}>
                  <li>Interior Design</li>
                </button>
                <button onClick={gotoContact}>
                  <li>Home Revamping</li>
                </button>
                <button onClick={gotoContact}>
                  <li>General Business Consulting</li>
                </button>
              </ul>
              <ul className="space-y-4 w-20 md:w-36 text-sm md:text-base flex flex-col items-center ">
                <button onClick={gotoContact}>
                  <li>Import/Export</li>
                </button>
                <button onClick={gotoContact}>
                  <li>Automobile Dealership</li>
                </button>
              </ul>
            </div>
          </div>

          {/* Bottom side */}
          <div className="flex flex-col items-center gap-8 justify-center">
            <div className="font-semibold text-center w-64">
              <h2> 1b Union Road,Aluminium Village, Dopemu Agege, Lagos</h2>
            </div>
            <div className="flex flex-col items-center gap-3">
              <div className="flex items-center gap-3">
                <img src={gmail} alt="Gmail" />
                <p>info@lcglobal.com.ng</p>
              </div>
              <div>
                <p>07017727500</p>
              </div>
            </div>
            <div className="flex items-center gap-2 md:gap-3 ">
              <img src={Youtube} alt="YouTube" className="w-12 md:w-auto" />
              <img src={x} alt="X" className="w-12 md:w-auto" />
              <img src={Instagram} alt="Instagram" className="w-12 md:w-auto" />
              <img src={Facebook} alt="Instagram" className="w-12 md:w-auto" />
              <img src={Whatsapp} alt="Instagram" className="w-12 md:w-auto" />
            </div>
            <div>
              <p className="capitalize">
                &copy; {year} Lcglobal. All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
