import React from "react";
import Compass from "../../ASSETS/compass.jpg";

const AimartHome = () => {
  return (
    <section className="w-full h-screen flex flex-col items-center md:flex-row">
      {/* Left side */}
      <div className="w-full md:w-1/2 p-8 md:p-32 flex flex-col gap-12 animate-slideInLeft">
        <h1 className="font-poppins text-3xl md:text-5xl font-bold text-center md:text-start ">
          Your Satisfaction, Our Priority.
        </h1>
        <p className="text-xl md:text-3xl font-semibold md:text-start text-center ">
          Navigate the Real Estate Market with Ease and Confidence Knowing Your
          Needs Are Our Focus.
        </p>

        <button className="bg-blue-800 w-full md:w-44 text-white py-3 rounded-full font-semibold hover:bg-white hover:border-2 hover:border-blue-600 hover:text-blue-600 transform transition-transform duration-300 hover:scale-110">
          Get Started
        </button>
      </div>
      {/* Right side */}
      <div className="w-full md:w-1/2 h-full">
        <img
          src={Compass}
          alt="Compass"
          className="object-cover w-full h-full"
        />
      </div>
    </section>
  );
};

export default AimartHome;
