import { useRef } from "react";
import React from "react";
import emailjs from "@emailjs/browser";

const LetsChat = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9x7wrrr",        
        "template_mx4z6to",        
        form.current,
        "BU8yNyIVUkHJsseMn"        
      )
      .then(
        () => {
          console.log("SUCCESS!");
          form.current.reset();  
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <section className="w-full bg-white mt-12 font-roboto min-h-[600px]">
      <div className="w-11/12 mx-auto gap-4 md:gap-0 flex flex-col md:flex-row items-start">
        {/* The Left Side */}
        <div className="bg-[#EDE0CF] w-full md:w-9/12 h-[300px] md:h-[600px] flex items-center justify-center p-10 md:p-16">
          <div className="space-y-8 flex flex-col md:items-start md:justify-start items-center justify-center">
            <h2 className="font-semibold text-3xl md:text-4xl text-[#212121]">Let’s Chat</h2>
            <p className="font-normal text-xl md:text-2xl text-[#212121] md:w-8/12">
              Whether you have a question, or simply want to make an enquiry.
            </p>
            <p className="font-normal text-xl md:text-2xl text-[#212121]">Feel free to send us a message</p>
          </div>
        </div>

        {/* The Form Side */}
        <div className="bg-[#111B31] w-full md:w-4/12 h-[600px] font-poppins flex items-center justify-center">
          <div className="w-11/12 md:w-9/12 mx-auto">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="bg-[#2B2D38] h-[450px] mx-auto space-y-4 border border-[#EDE0CF] rounded-2xl p-6"
            >
              {/* Email Field */}
              <div className="flex flex-col gap-3">
                <label className="text-white">Email</label>
                <input
                  className="bg-[#2B2D38] border border-gray-600 rounded-lg p-2 text-white placeholder:text-sm placeholder:text-slate-400"
                  type="email"
                  name="user_email"
                  placeholder="johndoe@gmail.com"
                  required
                />
              </div>

              {/* Service Dropdown */}
              <div className="flex flex-col gap-3">
                <label className="text-white">Service</label>
                <select
                  className="bg-[#2B2D38] border border-gray-600 rounded-lg p-2  text-sm text-slate-400"
                  name="service"
                  required
                >
                  <option value="real_estate">Real Estate Property Sale</option>
                  <option value="interior_design">Interior Design</option>
                  <option value="business_consulting">Business Consulting</option>
                </select>
              </div>

              {/* Message Field */}
              <div className="flex flex-col gap-3">
                <label className="text-white">Message</label>
                <textarea
                  className="border border-gray-600 bg-transparent rounded-xl h-32 text-white"
                  name="message"
                  placeholder="Write your message here"
                  required
                />
              </div>

              {/* Submit Button */}
              <div className="flex items-center justify-center">
                <input
                  type="submit"
                  value="Submit"
                  className="text-[#212121] font-normal text-lg bg-[#d9d9d9] px-6 py-2 rounded-full cursor-pointer transition-transform transform hover:scale-105 active:scale-95"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetsChat;
