import React, { useState, useEffect } from 'react';
import AboutUsHero from './AboutUsHero';
import WhoWeAre from './WhoWeAre';
import Services from '../Services/Services';
import whatsappIcon from "../../ASSETS/pngegg (12).png"; // Adjust path as necessary

const AboutUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Track window width to handle responsive changes
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate responsive WhatsApp button styles based on screen size
  const getWhatsAppButtonStyle = () => {
    let size = 60;
    let bottom = 40;
    let right = 40;

    if (windowWidth <= 768) { // Tablet size
      size = 50;
      bottom = 30;
      right = 30;
    }

    if (windowWidth <= 480) { // Mobile size
      size = 40;
      bottom = 20;
      right = 20;
    }

    return {
      position: 'fixed',
      width: `${size}px`,
      height: `${size}px`,
      bottom: `${bottom}px`,
      right: `${right}px`,
      backgroundColor: '#25D366',
      color: '#FFF',
      borderRadius: '50%',
      textAlign: 'center',
      fontSize: '30px',
      zIndex: 100,
      boxShadow: '2px 2px 3px #999',
      cursor: 'pointer'
    };
  };

  const whatsappIconStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  };

  return (
    <section>
      <AboutUsHero />
      <WhoWeAre />
      <Services id="service" />
      
      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/07017727500?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services"
        style={getWhatsAppButtonStyle()} // Apply responsive style
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="WhatsApp" style={whatsappIconStyle} />
      </a>
    </section>
  );
};

export default AboutUs;
