import React from "react";

const GetStarted = () => {
  return (
    <section className="w-full">
      <div className="flex flex-col md:flex-row items-center justify-between w-10/12 md:w-8/12 gap-10 mx-auto  py-20">
        <div className="space-y-7  w-full md:w-1/2 flex flex-col md:items-start items-center justify-center">
          <div className="w-16 h-1 bg-gray-600"></div>
          <div className="space-y-5">
            <h2 className="md:text-4xl text-3xl text-center text-zinc-700 font-bold md:text-start ">
              Discover your dream home today.
            </h2>
            <p className="text-zinc-700 md:text-start text-center"> 
              Take the first step towards landownership and schedule a viewing
              now!
            </p>
          </div>
        </div>
        <div>
          <button className="bg-blue-800 w-full md:w-44 text-white py-3 px-4 rounded-full font-semibold hover:bg-white hover:border-2 hover:border-blue-600 hover:text-blue-600 transform transition-transform duration-300 hover:scale-110">
            {" "}
            Get Started
          </button>
        </div>
      </div>
    </section>
  );
};

export default GetStarted;
