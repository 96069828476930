import React from 'react'
import LandsHero from '../Reuseable/LandsHero'
import Image from "../../ASSETS/forestRoad2.jpg"
import Image2 from "../../ASSETS/Palms-Park-logo-3-1024x619.png.webp"
import PlotSize from '../Reuseable/PlotSize'
import GetStarted from '../Reuseable/GetStarted'
import IbogunExperienceSection from '../Reuseable/IbogunExperienceSection'

const PalmsPark = () => {
  return (
    <section className='rounded-3xl w-11/12 mx-auto my-12'> 
        <div>
            <LandsHero 
            backgroundImage = {Image}
            Image = {Image2}
            text = "Aradagun, Badagry"
            />
        </div>
        <IbogunExperienceSection/>
        <PlotSize/>
        <GetStarted/>
    </section>
  )
}

export default PalmsPark
