import React from "react";
import telescope from "../../ASSETS/telescope.svg";
import mission from "../../ASSETS/mission.svg";
const Welcome = () => {
  return (
    <section className="w-full p-8 md:p-20  ">
        {/* Right side */}
      <article className="w-full  mx-auto flex flex-col md:w-10/12 md:flex-row items-center gap-28 justify-center">
        <div className="w-full space-y-8 md:space-y-12 md:w-1/2">
          <h2 className="text-2xl font-semibold md:text-4xl">
            Welcome to Aimart Realtors, your premier destination for exceptional
            real estate solutions in Nigeria.
          </h2>
          <p className="text-justify ">
            Established with a vision to redefine the real estate landscape, we
            specialize in providing unparalleled services for buying and selling
            land, guiding our clients towards their dreams of property ownership
            with expertise, integrity, and dedication.
          </p>
          <p className="text-justify">
            At Aimart Realtors, we understand that real estate transactions are
            more than just transactions; they are life-changing decisions.
            That’s why we are committed to delivering a seamless and rewarding
            experience to every client, whether they are seasoned investors or
            first-time buyers.
          </p>
        </div>

        {/* Left side */}
        <div className="w-full flex flex-col gap-20 md:w-1/2">
          <div className=" flex flex-col md:flex-row items-start gap-6">
            {/* Telescope Image */}
            <div className="relative flex-shrink-0 p-1 rounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-purple-950 bg-clip-border w-28">
              <div className="border border-transparent bg-white p-6 rounded-full">
                <img
                  src={telescope}
                  alt="Telescope"
                  className="w-full h-full"
                />
              </div>
            </div>
            {/* Telescope Content */}
            <div className="flex flex-col space-y-6">
              <h2 className="text-4xl font-semibold font-poppins ">Vision</h2>
              <p className="text-base w-full  md:w-96 text-justify">
                To be the leading real estate company in Africa that will
                provide the highest number of decent, accessible, comfortable,
                and affordable houses while achieving global housing standard.
              </p>
            </div>
          </div>
          <div className=" flex flex-col md:flex-row items-start gap-6">
            {/* Mission Image */}
            <div className="relative flex-shrink-0 p-1 rounded-full bg-gradient-to-r from-blue-500 via-purple-500 to-purple-950 bg-clip-border w-28">
              <div className="border border-transparent bg-white p-6 rounded-full">
                <img src={mission} alt="Telescope" className="w-full h-full" />
              </div>
            </div>
            {/* Mission Content */}
            <div className="flex flex-col space-y-6">
              <h2 className="text-4xl font-semibold font-poppins ">Misson</h2>
              <p className="text-base w-full  md:w-96 text-justify">
                To provide precise, accurate, and affordable real estate
                services that guarantee on one hand, wealth creation and
                preservation for our esteemed clients, and a robust Returns On
                Investments (ROIs) for our teeming clientele on the other hand.
              </p>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Welcome;
