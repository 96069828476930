import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SatisfiedClient from '../SatisfiedClient/SatisfiedClient';
import Testimonials from '../Testimonials/Testimonials';
import Hero from '../Hero/Hero';
import OurValue from '../Our Value/OurValue';
import MainLand from '../Project/MainLand';
import whatsappIcon from "../../ASSETS/pngegg (12).png"

const Home = () => {
  const mainlandRef = useRef(null);
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Scroll to Mainland section
  const scrollToMainland = () => {
    mainlandRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Check URL for scroll to projects
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollToProjects = params.get("scrollToProjects");

    if (scrollToProjects === "true") {
      scrollToMainland(); 
    }
  }, [location]);

  // Track window width to handle responsive changes
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate responsive WhatsApp button styles based on screen size
  const getWhatsAppButtonStyle = () => {
    let size = 60;
    let bottom = 40;
    let right = 40;

    if (windowWidth <= 768) { // Tablet size
      size = 50;
      bottom = 30;
      right = 30;
    }

    if (windowWidth <= 480) { // Mobile size
      size = 40;
      bottom = 20;
      right = 20;
    }

    return {
      position: 'fixed',
      width: `${size}px`,
      height: `${size}px`,
      bottom: `${bottom}px`,
      right: `${right}px`,
      backgroundColor: '#25D366',
      color: '#FFF',
      borderRadius: '50%',
      textAlign: 'center',
      fontSize: '30px',
      zIndex: 100,
      boxShadow: '2px 2px 3px #999',
      cursor: 'pointer'
    };
  };

  const whatsappIconStyle = {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  };

  return (
    <div>
      <Hero onScrollToMainland={scrollToMainland} />
      <OurValue onScrollToMainland={scrollToMainland} />
      <div ref={mainlandRef}>
        <MainLand />
      </div>
      <Testimonials />
      <SatisfiedClient />

      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/07017727500?text=Hello%20I%20would%20like%20to%20know%20more%20about%20your%20services"
        style={getWhatsAppButtonStyle()} // Apply responsive style
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="WhatsApp" style={whatsappIconStyle} />
      </a>
    </div>
  );
};

export default Home;
