import React from "react";

const PlotSize = () => {
  return (
    <section className="w-full h-auto bg-zinc-900 font-poppins flex justify-center">
      <div className="w-full md:w-8/12 mx-auto flex flex-col items-center justify-center gap-8 py-12 md:py-32">
        <h1 className="text-white font-poppins text-2xl  md:text-4xl font-bold uppercase">
          plot size
        </h1>
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 ">
          <div className="flex flex-col bg-zinc-700 w-80 md:w-96 h-40 md:h-52 items-center justify-center">
            <h1 className="text-white text-3xl">300</h1>
            <p className="uppercase text-white text-3xl">sqm</p>
          </div>
          <div className="flex flex-col bg-zinc-700 w-80 md:w-96 h-40 md:h-52 items-center justify-center">
            <h1 className="text-white text-3xl">500</h1>
            <p className="uppercase text-white text-3xl">sqm</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlotSize;
