import React from 'react';
import { NavLink } from 'react-router-dom';
import Image from "../../ASSETS/Rectangle 750.png";

const Hero = ({ onScrollToMainland }) => {
  return (
    <section className="w-full mt-12 font-asul ">
      <div className="w-11/12 p-4 lg:p-16 mx-auto rounded-3xl bg-gray-100 ">
        <div className="flex flex-col-reverse gap-6 lg:flex-row lg:gap-24">
          {/* Hero Left */}
          <div className="flex flex-col gap-6 lg:gap-28 ">
            <div className=" space-y-2 flex items-center lg:space-y-4 flex-col lg:items-start ">
              <h3 className="text-[#050F27] font-bold text-sm ">
                Lauref Classical Global
              </h3>
              <h2 className="font-semibold text-2xl">Let’s Partner with you</h2>
              <p className="text-lg w-72 text-justify">
                Crafted for your comfort, to enhance the beauty of your present
                lifestyle but also paves the way for a more aesthetically
                pleasing and harmonious future for both you and your cherished
                family.
              </p>
            </div>
            <div className="flex flex-col items-center space-y-3 lg:items-start lg:space-y-5">
              <h2 className="text-lg font-semibold">Introduction</h2>
              <p className="text-2xl w-[300px] text-center lg:text-start lg:text-4xl lg:w-60 font-semibold">
                A vision for liveable, sustainable & affordable.
              </p>
              <button
                onClick={onScrollToMainland} // Trigger scroll to Mainland
                className="bg-[#050F27] px-6 py-3 rounded-xl text-white"
              >
                Start Exploring
              </button>
            </div>
          </div>
          {/* Hero Right */}
          <div className="w-full lg:w-8/12 p-4 lg:px-6 mx-auto space-y-6">
            <h1 className="text-3xl lg:text-6xl lg:text-start text-center font-bold flex flex-col">
            Your trusted <span> partner in Real Estate</span>
            </h1>
            <img src={Image} alt="" className="w-full mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
