import React from 'react'
import Header from './Header'
import Welcome from './Welcome'
import WhatSetUsApart from './WhatSetUsApart'

const AimartAboutUs = () => {
  return (
  <section>
    <Header/>
    <Welcome/>
    <WhatSetUsApart />
  </section>
  )
}

export default AimartAboutUs
