import React from "react";

const LandsHero = (props) => {
  return (
    <section className="w-full relative h-full rounded-3xl">
      <div className="absolute inset-0 bg-black opacity-50 rounded-3xl z-10"></div>
      <div
        className="w-full h-screen bg-cover bg-center flex justify-center items-center rounded-t-3xl"
        style={{ backgroundImage: `url(${props.backgroundImage})` }}
      >
        <div className="flex flex-col items-center gap-16 md:gap-28 relative z-20">
          <img src={props.Image} alt="" className="w-80" />
          <div className="text-white text-3xl md:text-5xl font-bold text-center">
            <h2>{props.text}</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandsHero;
