import React from "react";
import Props from "../Props/Props";

const Services = () => {
  return (
    <section className="w-full font-asul mt-6 md:mt-2" id="service">
      <div className="w-11/12 bg-[#F7F8F8] py-12 px-6 space-y-10 rounded-[25px] lg:rounded-[52px]  mx-auto lg:p-20 lg:px-28">
        <h1 className="capitalize font-bold text-5xl text-center md:text-start">
          Services
        </h1>
        <div className="gap-3 md:gap-24 flex flex-col">
          <div className="flex flex-col gap-6 md:flex-row justify-between">
            <Props
              bgColor="bg-[#050F27]"
              textColor="text-white"
              number="01"
              heading="Real Estate Property Sales"
              para="The design of furniture holds substantial influence over the aesthetics, ambiance, and usability of an area, exerting a profound effect on our everyday experiences."
            />
            <Props
              bgColor="bg-[#fff]"
              textColor="text-[#050F27]"
              number="02"
              heading="Interior Details"
              para="Incorporating interior detailing introduces dimension, tactile qualities, and captivating visual elements to a room, enhancing the overall design through the addition of those final embellishments."
            />
            <Props
              bgColor="bg-[#fff]"
              textColor="text-[#050F27]"
              number="03"
              heading="Business Consulting"
              para="The design of furniture holds substantial influence over the aesthetics, ambiance, and usability of an area, exerting a profound effect on our everyday experiences."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
