import React from 'react'
import BgImage from "../../ASSETS/forestRoad1.jpg"
import Image from "../../ASSETS/covenant-park.png.webp"
import LandsHero from '../Reuseable/LandsHero'
import IbogunExperienceSection from '../Reuseable/IbogunExperienceSection'
import PlotSize from '../Reuseable/PlotSize'
import GetStarted from '../Reuseable/GetStarted'

const IbogunLekki = () => {
  return (
    <section className='rounded-3xl w-11/12 mx-auto my-12'>
        <div>
            <LandsHero 
                backgroundImage = {BgImage}            
                Image = {Image}
                text = "Ibogun Ibeju Lekki."
            />
        </div>
    <IbogunExperienceSection />
    <PlotSize/>
    <GetStarted />
    </section>
  )
}

export default IbogunLekki
