import React, { useState } from "react";
import logo from "../../ASSETS/WhatsApp_Image_2024-09-10_at_10.53.51_AM-removebg-preview.png";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll"; // Import react-scroll Link
import { NavLink, Link } from "react-router-dom";

const NavBar = ({onScrollToMainland}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); 
  const navigate = useNavigate(); 

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleProjectClick = () => {
    if (location.pathname === "/") {
      // If already on the homepage, scroll to the Projects section
      const projectSection = document.getElementById("mainland-section");
      if (projectSection) {
        projectSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      // If not on the homepage, navigate to the homepage with a scroll trigger
      navigate("/?scrollToProjects=true");
    }
  };

  return (
    <nav className="w-full mt-6 ">
      <div className="w-11/12 font-asul mx-auto h-12 md:h-auto rounded-full flex justify-between items-center bg-[#F7F8F8] pl-4">
        {/* Logo */}
        <div>
          <RouterLink to="/">
            <img src={logo} alt="Logo" className="md:w-16 w-10 " />
          </RouterLink>
        </div>
        {/* Hamburger Menu */}
        <div className="md:hidden w-16 h-16 flex items-center justify-center">
          <button onClick={toggleMenu} className="text-black focus:outline-none">
            <div className="w-8 h-6 md:h-8 flex flex-col justify-between items-center">
              <span
                className={`block w-full h-1 bg-black transition-transform transform ${
                  menuOpen ? "rotate-45 translate-y-2" : ""
                }`}
              ></span>
              <span
                className={`block w-full h-1 bg-black transition-opacity ${
                  menuOpen ? "opacity-0" : "opacity-100"
                }`}
              ></span>
              <span
                className={`block w-full h-1 bg-black transition-transform transform ${
                  menuOpen ? "-rotate-45 -translate-y-3" : ""
                }`}
              ></span>
            </div>
          </button>
        </div>
        {/* Menus */}
        <div className="hidden md:flex items-center gap-7 font-bold text-lg">
          <ul className="flex items-center gap-7">
            <li>
              <RouterLink to="/">Home</RouterLink>
            </li>
            <li>
              {/* Button to handle Projects click */}
              <button onClick={handleProjectClick} className="cursor-pointer">
                Projects
              </button>
            </li>
            <li>
              <RouterLink to="/about-us">About Us</RouterLink>
            </li>
          </ul>
        </div>
        {/* Contact */}
        <div className="hidden md:block">
          <RouterLink to="/contact-us">
            <button className="text-white bg-black w-44 h-16 rounded-r-full rounded-l-2xl">
              Contact Us
            </button>
          </RouterLink>
        </div>
      </div>
      {/* Mobile Menu Overlay */}
      {menuOpen && (
        <div className="md:hidden absolute top-28 rounded-3xl font-asul w-full bg-[#F7F8F8] p-6">
          <ul className="flex flex-col text-xl items-center gap-7 mt-4">
            <li>
              <RouterLink to="/" onClick={toggleMenu}>
                Home
              </RouterLink>
            </li>
            <li>
              
              <Link to="/about-us" onClick={toggleMenu}>
                Projects
              </Link>
            </li>
            <li>
              <RouterLink to="/about-us" onClick={toggleMenu}>
                About Us
              </RouterLink>
            </li>
            <li className="mt-4">
              <RouterLink to="/contact-us">
                <button
                  className="text-white bg-black w-44 h-16 rounded-full"
                  onClick={toggleMenu}
                >
                  Contact Us
                </button>
              </RouterLink>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default NavBar;
