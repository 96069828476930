import React from 'react';
import Image from "../../ASSETS/banner.png";

const Banner = () => {
  return (
    <section className="w-full mt-12 overflow-hidden"> 
      <img src={Image} alt="Banner" className="w-11/12 mx-auto h-52 md:h-80 " /> {/* Make image responsive */}
    </section>
  );
};

export default Banner;
