import React from "react";
import image from "../../ASSETS/Component 29.png";

const Testimonials = () => {
  return (
    <section className="w-full font-asul mt-10">
      <div className="w-11/12 bg-[#F7F8F8] h-auto rounded-[25px] lg:rounded-[52px] mx-auto py-12 lg:py-12 grid lg:grid-cols-3 gap-8 lg:p-20 lg:px-28">
        {/* Title spanning 2 columns */}
        <h1 className="text-[#050F27] text-center lg:text-start text-4xl lg:text-8xl lg:col-span-2">
          What our customers say
        </h1>

        {/* First article taking up the remaining 1 column next to the title */}
        <article className="bg-white rounded-[20px] lg:rounded-[45px] h-auto md:w-full w-11/12 mx-auto gap-3 p-6 lg:p-8 flex flex-col justify-center lg:justify-normal lg:gap-8">
          <p className="text-sm md:text-base">
          They make it super easy and safe to find a property to buy , prioritizing both due deligence and safety. The customer support team is exceptionally responsive and genuinely helpful, ensuring a smooth experience. I endorse LCGLOBAL.
          </p>
          <div className="flex flex-col items-end">
            <h2 className="text-[#000000] font-bold text-base md:text-xl">Martha Ikotun</h2>
            <img src={image} alt="Customer testimonial" />
          </div>
        </article>

        {/* Remaining articles filling the rows below */}
        <article className="bg-white rounded-[20px] lg:rounded-[45px] h-auto md:w-full w-11/12 mx-auto gap-3 p-6 lg:p-8 flex flex-col justify-center lg:justify-normal lg:gap-8">
          <p className="text-sm md:text-base">
          They turned around a small idea into a state of the art design for our company when we handed the the interior design project, it was a complete revamp beyond expectation. I wholeheartedly endorse LCGLOBAL Interior Design Service.
          </p>
          <div className="flex flex-col items-end">
            <h2 className="text-[#000000] font-bold text-base md:text-xl">GM Setlux</h2>
            <img src={image} alt="Customer testimonial" />
          </div>
        </article>

        <article className="bg-white rounded-[20px] lg:rounded-[45px] h-auto md:w-full w-11/12 mx-auto gap-3 p-6 lg:p-8 flex flex-col justify-center lg:justify-normal lg:gap-8">
          <p className="text-sm md:text-base">
          One of the genuine autodealers I’ve met, from shipping to clearing their service were top-notch. I recommend their consulting service to anyone looking at getting a reputable business handle their logistics or automobile deals.
          </p>
          <div className="flex flex-col items-end">
            <h2 className="text-[#000000] font-bold text-base md:text-xl">MD, Lampnet</h2>
            <img src={image} alt="Customer testimonial" />
          </div>
        </article>

        <article className="bg-white rounded-[20px] lg:rounded-[45px] h-auto md:w-full w-11/12 mx-auto gap-3 p-6 lg:p-8 flex flex-col justify-center lg:justify-normal lg:gap-8">
          <p className="text-sm md:text-base">
          They make it stress free to find a an office apartment for rent, prioritizing both convenience and safety. The customer support team is exceptionally responsive and genuinely helpful, ensuring a smooth experience. I recommend them
          </p>
          <div className="flex flex-col items-end">
            <h2 className="text-[#000000] font-bold text-base md:text-xl">Emmanuel Gbadamosi</h2>
            <img src={image} alt="Customer testimonial" />
          </div>
        </article>
      </div>
    </section>
  );
};

export default Testimonials;
