import React from 'react'

const Props = (props) => {
  return (
    <article className ={`rounded-[20px] lg:rounded-[45px] lg:h-auto  gap-3 p-4 lg:w-96 flex flex-col lg:flex-col justify-center lg:justify-normal lg:gap-8 ${props.bgColor} ${props.textColor}`}>
    <div className='flex flex-col gap-3 p-3 lg:gap-8 lg:p-8'>
        <h2 className='text-xl lg:text-5xl font-bold text-end'> {props.number}</h2>
        <div className='flex flex-col gap-6'>
            <h2 className='text-sm lg:text-5xl font-bold '>{props.heading}</h2>
            <p className='text-justify'>{props.para}</p>
        </div>
    </div>
</article>
  )
}

export default Props
