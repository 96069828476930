import React from "react";
import About_us from "../../ASSETS/About-Us.png";

const Header = () => {
  return (
    <section className="w-full relative h-72 md:h-96">
      <div className="absolute inset-0 bg-black opacity-85 z-10"></div>
      <div className="w-full h-full bg-cover bg-center" style={{backgroundImage: `url(${About_us})`}}>
        <div className="relative z-20 flex items-center justify-center h-full ">
          <div className="capitalize text-white text-4xl font-semibold font-poppins">About Us</div>
        </div>
      </div>
    </section>
  );
};

export default Header;
